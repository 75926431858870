import React, { useRef } from 'react'
import FolderIcon from '@mui/icons-material/Folder'
import { TextField, InputAdornment, Tooltip } from '@mui/material'
import IconButton from '@mui/material/IconButton'
import ClearIcon from '@mui/icons-material/Clear'
import Palette from '#/styles/Palette'
import Translate from '#/components/Translate'

const InputFile = ({
  value,
  defaultValue,
  onChange,
  disabled,
  accept = '',
  ...rest
}) => {
  const fileInput = useRef(null)
  const initial = value || defaultValue
  const [selectedFile, setSelectedFile] = React.useState({
    name: initial ? String(initial) : '',
  })

  const handleChange = async () => {
    if (fileInput?.current?.files?.[0]) {
      const file = fileInput.current.files[0]

      setSelectedFile(file)
      onChange?.(file)
    }
  }

  const handleClear = e => {
    setSelectedFile({ name: '' })
    onChange?.(null)
    e.stopPropagation()
  }

  return (
    <>
      <TextField
        {...rest}
        type="text"
        label={rest.label}
        value={selectedFile.name}
        placeholder={Translate({
          messageKey: 'select_one_item_male',
          params: {
            item: Translate({ messageKey: 'file', lower: true }),
          },
        })}
        InputProps={{
          readOnly: true,
          onClick: () => (!disabled ? fileInput.current?.click() : {}),
          endAdornment: (
            <InputAdornment position="end">
              {!disabled && selectedFile.name ? (
                <Tooltip title={Translate({ messageKey: 'clear' })}>
                  <IconButton onClick={handleClear}>
                    <ClearIcon />
                  </IconButton>
                </Tooltip>
              ) : null}
              <FolderIcon style={{ color: Palette.gray.dark[500] }} />
            </InputAdornment>
          ),
        }}
        disabled={!!disabled}
        fullWidth
      />
      <input
        ref={fileInput}
        type="file"
        accept={accept}
        style={{ display: 'none' }}
        onChange={handleChange}
      />
    </>
  )
}

export default InputFile
